import { useState, useEffect } from "react";
import "../../node_modules/video-react/dist/video-react.css";

import { useParams } from "react-router-dom";
import  Modal from "../components/Modal";

const GroupPage = () => {
  let {groupId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [techniqueGroupName, setTechniqueGroupName] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/institution/ddkk/techniquegroup/${groupId}`, {})
      .then((res) => res.json())
      .then((response) => {
        setData(response.techniqueGroup.techniques);
        setTechniqueGroupName(response.techniqueGroup.name);
        setIsLoading(false);
        console.log(response);
      })
      .catch((error) => console.log(error));
  }, [groupId]);

  return (
    <>
      <main>
        {!isLoading && (
          <div>
            <div>
              <h1 className="PageTitle">{techniqueGroupName}</h1>
              <ol>
                {data.map((item: { id: string, name: string, usage: string, image: string, videoSlow: string, videoFast: string, videoDetail: string, steps: Array<{ description: string, detail: string, image: string, videoSlow: string, videoFast: string, videoDetail: string }> }) =>
                  <li key={item.id}><div id={item.id} /><b>{item.name}</b> :: {item.usage} {item.videoSlow || item.videoFast || item.videoDetail ? <span> :: Demo:</span> : ""}
                    {item.videoDetail ?
                      <>
                        <Modal LinkText="Detail" VideoSource={item.videoDetail} />
                      </>
                      : ""}
                    {item.videoSlow ?
                      <>
                        <Modal LinkText="Slow" VideoSource={item.videoSlow} />
                      </>
                      : ""}
                    {item.videoFast ?
                      <>
                        <Modal LinkText="Fast" VideoSource={item.videoFast} />
                      </>
                      : ""}
                    <ol>
                      {item.steps.map((child: { description: string, detail: string, videoSlow: string, videoFast: string }, index) =>
                        <>
                          <li className="childSteps" key={index}>{child.description} {child.videoSlow || child.videoFast ? <span> :: Demo:</span> : ""} {child.videoSlow ? <a href={child.videoSlow}>slow</a> : ""} {child.videoFast ? <a href={child.videoFast}>fast</a> : ""} </li>
                          <sup>{child.detail}</sup>
                        </>
                      )}
                    </ol>
                  </li>
                )}
              </ol>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default GroupPage;