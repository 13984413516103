import React from 'react';
import client from 'react-dom/client';
import './index.css';
import App from './App';

const root = client.createRoot(document.getElementById('root')!);

root.render (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);