import { useState, useEffect } from "react";
import Footer from "./Footer";

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/institution/ddkk/techniquegroups`, {})
      .then((res) => res.json())
      .then((response) => {
        setData(response.techniqueGroups);
        setIsLoading(false);
        console.log("results" + response);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <main>
        {!isLoading &&
          <div>
            <h1 className="PageTitle">Learning Goals:</h1>
            <ul>
              {data.map((item: { id: string, name: string, description: string, image: string }) =>
                <li key={item.id}><a href={'/' + item.id}><img className="tinyImage" src={item.image} alt={item.name} /></a>{item.description}</li>
              )}
            </ul>
          </div>
        }
      </main>
      <Footer />
    </>
  );
};

export default HomePage;