import { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {
  const [brand, setBrand] = useState({ name: "Home", id: "/", image: "", facebook: "", twitter: "" });
  const [phones, setPhone] = useState([]);
  const [webSite, setWebSite] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/institution/ddkk/contactinformation`)
      .then(res => res.json())
      .then((data) => {
        setPhone(data.contactInformation.phones);
        setWebSite(data.contactInformation.webSites);
        setBrand({ name: data.name, id: data.id, image: data.imageUri, facebook: data.contactInformation.facebook, twitter: data.contactInformation.twitter })

        //setIsLoading(false);
      })
      .catch(console.log)
  }, []);

  const Phones: any = () => phones.map((link: { name: string, number: string }) => <span key={link.name} className="footerItems"><a href={"tel:" + link.number}><img src='/images/app/phone.png' className="socialmedia" alt='phone' /></a></span>);
  const WebSite: any = () => webSite.map((link: { name: string, address: string }) => <span key={link.name} className="footerItems"><a href={link.address} target="_blank" rel="noreferrer"><img src='/images/app/website.png' className="socialmedia" alt='website' /></a></span>);
  return (
    <footer className="footerItems">
      <div>
        <span className="footerItems">© {(new Date().getFullYear())} {brand.name}</span>
        <Phones />
        <WebSite />
        <span className="footerItems"><a href={brand.facebook} target="_blank" rel="noreferrer"><img src='/images/app/facebook.png' className="socialmedia" alt='facebook' /></a></span>
        <span className="footerItems"><a href={brand.twitter} target="_blank" rel="noreferrer"><img src='/images/app/twitter.png' className="socialmedia" alt='twitter' /></a></span>
      </div>
    </footer>
  )
};

export default Footer;