import { useEffect, useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [content, setContent] = useState([]);
  const [brand, setBrand] = useState({ name: "Home", id: "/", image: "" });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/institution/ddkk/techniquegroups`)
      .then(res => res.json())
      .then((data) => {
        setContent(data.techniqueGroups);
        setBrand({ name: "Home", id: "/", image: data.imageUri })
      })
      .catch(console.log)
  }, []);

  const NavLinks: any = () => content.map((link: { name: string, id: string, image: string }) => <span key={link.name}><a href={link.id}><img src={link.image} alt={link.name} /></a></span>);
  return (
    <header>
      <div>
        <span key={brand.name}><a href={brand.id}><img src={brand.image} alt={brand.name} /></a></span>
        <NavLinks />
        <span key='Search' className="searchIcon"><a href='/search'><img src='/images/app/search-icon.png' alt='search' /></a></span>
      </div>
    </header>
  )
};

export default Navbar;