import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.scss';

import HomePage from "./components/HomePage";
import Navbar from './components/Navbar';
import SearchPage from './components/SearchPage';
import GroupPage from './components/GroupPage';

const App = () => {
  return (
    <>
      <Router>
        <div id="app">
          <Navbar />
          <Routes>
            <Route exact path="/" element={ <HomePage />} />
            <Route path="/search" element={ <SearchPage />} />
            <Route path="/:groupId" element={ <GroupPage />} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;