import axios from "axios";
import { useState, useEffect } from "react";
import { HashLink } from 'react-router-hash-link';

function SearchPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Search");

  useEffect(() => {
    if (query.length < 3) {
      return;
    }

    const fetchData = async () => {
      const response = await axios(`${process.env.REACT_APP_API}/api/institution/ddkk/techniquegroup/search/${query}`);
      setData(response.data.list);
      setPageTitle("Search Results");
      setIsLoading(false);
      console.log(response);
    };

    fetchData();
  }, [query]);

  return (
    <>
      <main>
        <div>
          <div>
            <h1 className="PageTitle">{pageTitle}</h1>
            <div className="searchText">
              <input autoFocus key="searchText" type="text" value={query} onChange={e => setQuery(e.target.value)} />
            </div>
            <div>
              {!isLoading && (
                <ol>
                  {data.map((item: { parentId: string, parentName: string, parentImage: string, id: string, name: string, searchHit: string }) =>
                    <li id={item.id}><a href={"/" + item.parentId}><img className="tinyImage" src={item.parentImage} alt={item.name} /></a>{item.parentName} :: <HashLink smooth to={"/" + item.parentId + "#" + item.id}><b>{item.name}</b></HashLink> {item.searchHit !== "" ? " :: " + item.searchHit : ""}</li>
                  )}
                </ol>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SearchPage;